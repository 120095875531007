<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 100 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.255 120.558C71.8009 120.558 92.51 118.605 92.51 116.196C92.51 113.786 71.8009 111.833 46.255 111.833C20.7091 111.833 0 113.786 0 116.196C0 118.605 20.7091 120.558 46.255 120.558Z"
      fill="#666"
    />
    <path
      d="M60.9556 116.339L60.229 111L56 112.034L58.9901 117.051C58.8471 117.118 59.1092 117.24 59.1092 117.24H67.2931C67.2931 117.24 66.626 114.593 60.9556 116.339Z"
      fill="#C7C7C7"
    />
    <path
      d="M40.2321 114.854L43 106H37.3966V115.31C36.5101 115.737 35.7002 116.308 35 117H43C42.7096 116.472 42.3142 116.009 41.8381 115.64C41.362 115.271 40.8154 115.003 40.2321 114.854Z"
      fill="#C7C7C7"
    />
    <path
      d="M34.2738 66.3155C34.6605 62.2604 35.7 54.5 35.7 54.5L53 55C53 55 62.1287 84.179 62.6821 88.7078C63.8631 98.3717 61.3733 115.088 61.3733 115.088L56.0302 116C56.0302 116 55.402 113.506 55.0588 111.896C53.2411 103.371 53.7571 94.8931 53.034 89.7142C51.7089 80.2237 45.2619 71.5991 45.2619 71.5991C45.2619 71.5991 44.6449 93.1001 44.1899 101.539C43.7592 109.526 42.4298 114.632 42.4298 114.632L36.1153 114.176C34.5073 105.118 34.2738 105.335 34.2738 100.03V82.921C34.2738 76.4362 33.6578 72.7745 34.2738 66.3155Z"
      fill="#454445"
    />
    <path
      d="M42 24.0758H47.9097V28.7547L51.9463 29.8556C51.9463 29.8556 48.3758 39.0758 44.8053 39.0758C41.4319 39.0758 37.9463 29.8556 37.9463 29.8556L42 28.7547V24.0758Z"
      fill="#B9B9B9"
    />
    <path
      d="M48.5 23C48.5 25.2973 48.8546 25.2272 48.5 27C48.2696 28.1523 46.2992 28.1075 45.2992 27.6075C43.9731 26.9445 43.4294 26.7615 42.5 26C40.556 24.4071 40.1492 24.4151 40.1492 22.1178C40.1492 18.2393 42.4549 15.0951 45.2992 15.0951C46.3586 15.4448 48.1968 15.0951 48.5984 20C49 22.5 50 23 48.5 23Z"
      fill="#C7C7C7"
    />
    <path
      d="M37.5182 69.0728L37.94 66.1201C37.9442 66.0909 37.9422 66.0623 37.9333 66.0342C37.8133 65.6571 36.8645 62.7629 35 59.5C33.1962 56.3434 32.4866 55.0425 32 53.5C31.0537 50.5 31.3269 49.1923 31.5 48.5C32 46.5 32 46.5 33 44.0001L33 44C33.9469 41.6328 36 38 36 39.5758C36 40.92 35.5067 47.2974 35 52C34.6415 55.3266 34.9266 57.3763 35.0223 57.926C35.0378 58.015 35.1133 58.0788 35.2035 58.0831L54.4827 58.9992C54.4942 58.9997 54.5039 59.0002 54.5149 58.9967C55.4882 58.6928 54.0446 40.7626 53.5 39.5758C51.9736 36.2494 57 43.5 59 49.5C59.368 50.6041 60.6804 51.9522 57.5 56C52.0001 63 53.0507 66.5758 53.0507 66.5758L54.3906 68.8089C54.4655 68.9337 54.6408 68.9438 54.7223 68.8232C55.8849 67.1022 62.5872 57.0202 63.5 52C63.7683 50.5242 62.5001 45.8111 62.5001 45.8111C62.5001 45.8111 60 33.5 55 30C54.2428 29.4699 50.2023 29.2646 50.2023 29.2646C50.2023 29.2646 47.067 33.3426 44.2256 33.1367C41.6574 32.9507 39.991 29.2646 39.991 29.2646C39.991 29.2646 37.6881 29.0988 36.7141 29.2646C31.9463 30.0758 30.5001 34 27.5 48C26.4198 53.0407 35.4264 66.6077 37.1606 69.159C37.2627 69.3092 37.4925 69.2526 37.5182 69.0728Z"
      fill="#666666"
    />
    <path d="M51.9463 65.0758L54.9463 70.0758" stroke="#454445" stroke-width="0.5" stroke-linecap="round" />
    <path
      d="M45.5264 19.7535C46.7375 19.3113 48.0001 16.0001 48.0001 16.0001L46.1699 16.3945C46.1699 16.3945 46.3958 18.2544 45.683 19.1455C45.1238 19.8445 44.5019 18.7807 44.5019 18.7807C44.5019 18.7807 44.8086 20.0156 45.5264 19.7535Z"
      fill="#666"
    />
    <path
      d="M46.2042 15.9921L46.3161 15.8621C46.3161 15.8621 44.4463 13.0758 41.3307 16.5228C40.3526 17.605 40.9471 17.3211 40.3553 17.9524C39.1043 19.2871 39 19.5 39 20.5C39 20.8284 39.331 21.041 39.5 22C39.5659 22.3741 39.3683 22.7686 39.5 23C39.6789 23.3142 40.2142 25.5 41.7142 25C41.7142 25 40 24.5 40.5 23C40.616 22.7033 40.8536 22.4425 41 22C41.1388 21.5803 40.9152 21.5627 40.9092 21.2022C40.9032 20.8418 41.0488 19.8309 41.3789 19.4061C41.7928 18.8734 41.3707 18.463 41.7142 17.7338C42.1606 16.786 46.2042 15.9921 46.2042 15.9921Z"
      fill="#666"
    />
    <path
      d="M47.5 17C47.7854 16.6214 47.9351 16.3869 48.0889 15.9385C48.8888 13.6071 45.6984 13.1298 41.8064 16.5433C40.5845 17.6149 40.7087 17.1038 40.5919 17.9626C40.178 21.0077 38.8583 19.187 39.5472 21.002C39.7593 21.561 39.2713 21.4773 40.5 21.5C41.7612 21.5233 40.1534 22.7655 40 23C39.7999 23.3059 40.1962 25.3442 41.7272 26.177C41.8362 26.2446 41.9161 26.2709 41.9161 26.2709C41.8513 26.2417 41.7883 26.2103 41.7272 26.177C41.3256 25.9282 40.5295 25.1194 41.8792 23.0013C42.1287 22.6098 41.3391 21.5809 41.3274 21.2203C41.3157 20.8596 41.488 19.8499 41.8997 19.4286C42.416 18.9002 42.248 19.2547 42.6731 18.529C43.2257 17.5857 46.3099 18.5791 47.5 17Z"
      fill="#666"
    />
    <path
      d="M51 42.5C51 42.5 49.4553 35.5 51 31.5C52.8045 26.8272 60.2252 22.1437 61.2432 26.6897C61.494 27.8093 60.0651 29.1942 58.7838 28.1379C57.027 26.6897 57.0602 24.9186 58.7941 21.36C59.8907 19.1093 63 18 63 18"
      stroke="var(--primary)"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-dasharray="0.5 0.5"
    />
    <ellipse
      cx="66.7346"
      cy="15.9964"
      rx="0.75"
      ry="0.75"
      transform="rotate(61.6544 66.7346 15.9964)"
      fill="var(--primary)"
    />
    <path
      d="M66.3073 15.2043C65.9773 15.3823 65.424 15.1467 65.1487 14.6363C64.8734 14.1259 64.6502 13.7122 65.3103 13.3561C65.9703 13 66.1935 13.4137 66.4689 13.9241C66.7442 14.4345 66.6374 15.0263 66.3073 15.2043Z"
      fill="var(--primary)"
    />
    <path
      d="M68.159 18.6367C67.4989 18.9928 67.2757 18.579 67.0004 18.0687C66.7251 17.5583 66.8319 16.9665 67.1619 16.7885C67.492 16.6104 68.0452 16.8461 68.3206 17.3565C68.5959 17.8668 68.8191 18.2806 68.159 18.6367Z"
      fill="var(--primary)"
    />
    <path
      d="M65.9225 16.8606C65.7403 16.9589 65.1828 17.0689 65.0844 16.8866C64.9861 16.7044 65.3842 16.2988 65.5664 16.2005C65.7487 16.1022 65.9762 16.1702 66.0745 16.3525C66.1729 16.5348 66.1048 16.7622 65.9225 16.8606Z"
      fill="var(--primary)"
    />
    <path
      d="M65.2625 17.2167C65.0802 17.315 64.5227 17.425 64.4244 17.2427C64.3261 17.0604 64.7241 16.6549 64.9064 16.5566C65.0887 16.4583 65.3162 16.5263 65.4145 16.7086C65.5128 16.8909 65.4448 17.1183 65.2625 17.2167Z"
      fill="var(--primary)"
    />
    <path
      d="M52 72.1001L51.4999 43.5L32.4999 44L32.2 70.1001L52 72.1001Z"
      fill="var(--box-side, var(--primary)"
      stroke="var(--box-side, var(--primary)"
    />
    <path d="M51.5 45.0001V43.5L32.5 44V45.0001H51.5Z" fill="var(--primary)" stroke="var(--primary)" />
    <path d="M64 70.0001V44.0001L52 43.5001V72.0001L64 70.0001Z" fill="var(--primary)" stroke="var(--primary)" />
    <path
      d="M64 45.0001V44.0001L52 43.5001V45.0001H64Z"
      fill="var(--box-side, var(--primary)"
      stroke="var(--box-side, var(--primary)"
    />
    <path
      d="M25 50.9076L32.1234 44L52.1986 45L44.6434 50.9076H25Z"
      fill="var(--box-edge, var(--primary))"
      stroke="var(--box-edge, var(--primary))"
    />
    <path
      d="M56.9476 51.1234L52.1986 45L64.5 44L68.5 51.1234H56.9476Z"
      fill="var(--box-edge, var(--primary))"
      stroke="var(--box-edge, var(--primary))"
    />
    <ellipse cx="64.8" cy="59.625" rx="0.375" ry="1" transform="rotate(-90 64.8 59.625)" fill="#D9D9D9" />
    <ellipse cx="64.8" cy="58.875" rx="0.375" ry="1" transform="rotate(-90 64.8 58.875)" fill="#D9D9D9" />
    <ellipse cx="64.8" cy="58.125" rx="0.375" ry="1" transform="rotate(-90 64.8 58.125)" fill="#D9D9D9" />
    <ellipse cx="64.8" cy="57.375" rx="0.375" ry="1" transform="rotate(-90 64.8 57.375)" fill="#D9D9D9" />
    <ellipse
      cx="37.4226"
      cy="71.1177"
      rx="0.375"
      ry="0.588527"
      transform="rotate(4.77445 37.4226 71.1177)"
      fill="#D9D9D9"
    />
    <ellipse
      cx="38.1701"
      cy="71.1801"
      rx="0.375"
      ry="0.588527"
      transform="rotate(4.77445 38.1701 71.1801)"
      fill="#D9D9D9"
    />
    <ellipse
      cx="38.9174"
      cy="71.2426"
      rx="0.375"
      ry="0.588527"
      transform="rotate(4.77445 38.9174 71.2426)"
      fill="#D9D9D9"
    />
    <ellipse
      cx="39.6648"
      cy="71.305"
      rx="0.375"
      ry="0.588527"
      transform="rotate(4.77445 39.6648 71.305)"
      fill="#D9D9D9"
    />
  </svg>
</template>
