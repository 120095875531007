<!-- eslint-disable max-len -->
<template>
  <svg width="190" height="217" viewBox="0 0 190 217" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M173.514 96.0269L125.216 20.204L125.574 19.9673L174.052 95.6719L173.514 96.0269Z"
      fill="#666666"
      stroke="#666666"
      stroke-width="1.84"
    />
    <path
      d="M147.291 21.5782L137.565 11.9876C137.565 11.9876 143.993 10.3326 145.597 9.51647C147.201 8.70031 148.805 7.88415 152.002 4.64209L160.929 15.0432C155.329 19.9119 155.15 20.0029 152.12 21.5443C150.516 22.3604 148.907 22.3717 147.291 21.5782Z"
      fill="var(--flag)"
    />
    <path
      d="M139.173 11.6546C132.728 10.895 129.833 15.6013 125.543 19.3162L133.682 32.1376C133.682 32.1376 139.265 24.8543 141.669 23.2277C144.072 21.601 144.877 21.5953 147.291 21.5784L139.173 11.6546Z"
      fill="var(--primary)"
    />
    <path
      d="M62.7176 213.84H85.3252C85.3252 211.418 72.9276 184.044 61.2592 179.078C50.32 196.813 37.9223 213.84 37.9223 213.84H62.7176Z"
      fill="#666666"
    />
    <path
      d="M127.777 213.794C144.659 213.794 153.145 213.897 154.85 213.794C153.767 211.846 144.617 197.249 137.523 191.719C131.825 193.562 119.034 213.794 119.034 213.794H127.777Z"
      fill="#666666"
    />
    <path
      d="M44.4395 47.7583C44.4395 47.7583 40.2394 44.052 39.4345 42.4422C39.1852 41.9436 40.2393 40.8324 40.9264 41.8163C41.0442 40.8324 41.7313 40.2065 42.5361 41.0114C42.15 40.6128 43.0822 39.0624 43.9044 40.1598C43.9044 40.1598 43.8587 39.495 44.3033 39.0967C44.7728 38.676 45.0686 38.4178 45.8735 39.2226C46.6784 40.0275 48.1705 45.8407 48.1705 45.8407L44.4395 47.7583Z"
      fill="#D9D9D9"
    />
    <path
      d="M81.7672 48.1197C83.3645 41.7543 93.6833 44.2399 93.6833 44.2399L93.0839 45.0044C93.0839 45.0044 87.7849 46.9904 85.921 49.5832C83.3764 53.1227 84.4569 59.0955 86.3971 62.7772C88.061 65.9346 91.8558 65.3528 92.4215 66.6692C92.9872 67.9857 91.2558 70.1205 91.064 71.2336C90.463 72.3041 86.3585 72.2483 86.524 73.4041C88.2977 85.7898 92.2387 105.121 84.4804 98.4664C82.9867 97.1853 81.0187 97.4796 80.4233 94.3156C79.8796 91.1381 80.2503 87.9934 79.0841 87.1965C75.7523 84.4703 74.1479 81.3838 76.6561 78.6442C79.3736 75.676 77.4128 72.7513 79.2403 66.7012C80.8803 61.2717 78.42 59.7583 78.8878 56.1204C79.3535 52.4982 80.6655 52.51 81.7672 48.1197Z"
      fill="#454445"
    />
    <path
      d="M91.6045 44.6939L91.4188 43.9257C91.4188 43.9257 95.9936 42.4322 98.884 44.9999C101.403 47.2378 100.233 49.8989 101.569 52.8719C103.043 56.1538 104.232 55.4383 106.26 57.5137C108.586 61.1712 105.72 63.3484 111.703 64.6798C116.517 67.3085 115.848 68.6091 116.011 74.5164C116.08 77.0165 118.635 81.3564 117.648 83.2175C117.141 84.1737 115.68 85.8178 116.019 87.619C116.465 89.992 117.405 90.6342 112.612 95.631C112.612 95.631 106.494 81.8396 103.68 69.9307C103.216 67.9635 99.4896 70.2564 98.4958 68.5286C97.7845 67.2917 98.061 66.217 98.0162 65.268C99.1939 61.576 101.003 56.4647 99.8894 53.1878C98.4418 48.9283 91.6045 44.6939 91.6045 44.6939Z"
      fill="#454445"
    />
    <path
      d="M88.2382 61.8472L97.5875 60.0883L98.98 67.4904L105.694 68.0307C105.694 68.0307 102.789 83.6799 97.1407 84.7425C91.8039 85.7466 83.5454 72.1975 83.5454 72.1975L89.6307 69.2493L88.2382 61.8472Z"
      fill="#B9B9B9"
    />
    <path
      d="M100.563 54.652C101.247 58.2863 100.881 60.9018 98.512 64.1309C97.393 65.6561 96.9748 66.8303 94.5059 67.2948C92.1604 67.7361 91.3208 66.7311 89.6239 65.803C86.0744 63.8616 84.9521 61.3518 84.2684 57.7175C83.114 51.5816 85.8259 45.9212 90.3256 45.0746C94.8253 44.2281 99.4088 48.516 100.563 54.652Z"
      fill="#C7C7C7"
    />
    <path
      d="M95.1606 217H142C139.285 214.442 108.058 182.208 95.1606 181.184C82.9416 181.184 51.0365 214.442 49 217H95.1606Z"
      fill="var(--content-hover)"
    />
    <path
      d="M79.478 184.011L79.478 177.858L84.832 176.767L84.1786 186.584C84.4124 186.622 84.1034 186.927 84.1034 186.927L74.3685 192.865C74.3685 192.865 73.9212 186.927 79.478 184.011Z"
      fill="#C7C7C7"
    />
    <path
      d="M125.881 205.823L123.989 199.448L129.75 196.519L131.854 205.479C132.083 205.418 131.926 205.823 131.926 205.823L126.686 209.767C126.686 209.767 117.77 209.32 125.881 205.823Z"
      fill="#C7C7C7"
    />
    <path
      d="M80.0026 130.084C83.6401 124.627 88.6561 116.722 88.6561 116.722L116.827 113.181C116.827 113.181 122.375 124.725 123.266 132.015C123.901 137.212 123.466 153.425 123.466 159.059C123.466 164.694 123.266 170.139 126.486 180.791C127.491 188.035 130.51 197.883 130.51 197.883L123.266 200.914C123.266 200.914 120.594 192.474 119.242 189.834C114.613 180.791 112.923 170.328 111.314 163.889C107.572 148.924 103.344 135.718 103.344 135.718C103.344 135.718 86.6098 142.255 85.8773 155.84C85.184 168.697 85.8773 180.791 85.8773 180.791L78.0286 179.986C74.5103 171.6 77.147 178.377 75.1733 168.718C73.1996 159.059 71.9538 153.425 73.5636 146.181C75.1733 138.937 73.5635 139.742 80.0026 130.084Z"
      fill="#666666"
    />
    <path
      d="M48.4387 44.8814L43.9442 47.8777C43.8565 47.9362 43.8257 48.0427 43.8888 48.1271C45.2485 49.9438 59.3109 68.6583 63.9049 71.721C68.7342 74.9405 75.7775 80.3693 75.7775 80.3693C75.7775 80.3693 82.6214 84.3018 83.0215 86.4284C83.4216 88.5551 84.8771 100.517 85.475 108.107C85.8928 113.411 86.9329 116.556 87.262 117.444C87.3198 117.599 87.4778 117.688 87.6424 117.665L118.376 113.382L118.378 113.382C118.398 113.379 118.416 113.377 118.433 113.366C119.856 112.546 111.168 83.6035 110.725 81.244C109.635 75.454 114.312 80.938 122.347 86.4284C124.227 87.7128 131.299 90.4774 134.612 90.6731C139.297 90.95 150.267 60.7008 150.267 60.7008L146.022 58.4431C145.85 58.3519 145.643 58.4005 145.542 58.566C144.022 61.0433 133.102 78.9008 132.184 82.1208C131.69 83.8517 126.163 79.8828 124.023 77.9231C118.086 72.4879 117.937 71.6969 112.299 67.8438C110.07 66.3208 102.759 67.615 102.759 67.615C102.759 67.615 99.0122 74.9996 94.4559 75.5196C90.3376 75.9896 86.6042 70.6541 86.6042 70.6541C86.6042 70.6541 79.8825 71.0792 78.3928 70.522C75.5405 69.4552 72.7586 68.1074 68.7341 65.2509C63.8087 61.7551 55.8561 52.8146 52.6366 49.595C50.0399 46.9983 49.0139 45.4488 48.7142 44.9465C48.6575 44.8515 48.5308 44.82 48.4387 44.8814Z"
      fill="var(--primary)"
    />
    <path
      d="M152.322 58.2039L150.057 60.4698L148.032 58.9517C148.032 58.9517 144.745 55.2938 145.251 54.7877C145.757 54.2817 147.526 56.9275 147.526 56.9275L149.276 53.9829C150.081 52.3731 150.464 53.178 150.464 53.178C150.464 53.178 150.365 52.6815 150.563 52.8791L151.139 53.4554L152.081 54.3973L153.504 55.8205L154.105 56.4214C154.105 56.4214 153.228 57.507 152.322 58.2039Z"
      fill="#C7C7C7"
    />
    <path
      d="M154.105 56.4214L152.587 57.9396L150.057 60.4698L148.032 58.9517C148.032 58.9517 144.745 55.2938 145.251 54.7877C145.757 54.2817 147.526 56.9275 147.526 56.9275L149.276 53.9829C150.081 52.3731 150.464 53.178 150.464 53.178C150.464 53.178 150.365 52.6815 150.563 52.8791C150.788 53.1042 151.139 53.4554 151.139 53.4554L152.081 54.3973C152.081 54.3973 153.093 55.4093 153.504 55.8205C153.915 56.2317 154.105 56.4214 154.105 56.4214ZM154.105 56.4214C154.105 56.4214 151.353 59.829 150.563 58.4456"
      stroke="#C7C7C7"
      stroke-width="1.84"
      stroke-linejoin="round"
    />
  </svg>
</template>
