<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 100 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2589_355)">
      <path
        d="M46.253 122.592C71.7978 122.592 92.506 120.319 92.506 117.516C92.506 114.713 71.7978 112.44 46.253 112.44C20.7082 112.44 0 114.713 0 117.516C0 120.319 20.7082 122.592 46.253 122.592Z"
        fill="#666"
      />
      <path
        d="M23.4012 116.247L25.0399 111.011H21.7226V116.517C21.1977 116.769 20.7183 117.107 20.3037 117.516H25.0399C24.868 117.204 24.6339 116.93 24.3521 116.712C24.0702 116.493 23.7466 116.335 23.4012 116.247Z"
        fill="#C7C7C7"
      />
      <path
        d="M55.006 115.817L54.3965 111.021L50.8494 111.95L53.3574 116.457C53.2375 116.517 53.4573 116.627 53.4573 116.627H60.3218C60.3218 116.627 59.7622 114.249 55.006 115.817Z"
        fill="#C7C7C7"
      />
      <path
        d="M31.7345 53.4272C31.6246 53.4872 28.9168 56.4248 28.8269 56.5647C28.0875 57.3841 27.438 58.6331 26.7185 69.3645C26.3229 77.0542 25.1478 84.6839 23.2114 92.1363C22.422 94.8541 19.5942 101.479 20.4336 111.031C20.4507 111.271 20.546 111.499 20.7048 111.679C20.8636 111.859 21.0772 111.983 21.3129 112.03L25.0199 112.76C25.2708 112.81 25.5312 112.771 25.757 112.651C25.9828 112.531 26.1601 112.336 26.2589 112.1C27.5579 108.983 32.1442 97.8517 33.8728 92.1163C35.4615 86.8905 37.7997 75.8093 38.669 70.4336C38.669 70.3011 38.7216 70.174 38.8153 70.0804C38.909 69.9867 39.0361 69.934 39.1686 69.934C39.3011 69.934 39.4282 69.9867 39.5219 70.0804C39.6155 70.174 39.6682 70.3011 39.6682 70.4336C40.8972 76.179 43.595 88.729 44.3145 91.0671C45.2237 94.0647 46.2529 107.554 49.7002 112.38C49.8131 112.536 49.9643 112.661 50.1394 112.741C50.3145 112.822 50.5073 112.856 50.6994 112.84L54.0567 112.4C54.3237 112.38 54.5739 112.262 54.7585 112.068C54.9432 111.874 55.0492 111.619 55.0559 111.351C55.2757 99.8801 45.6134 55.8453 44.9939 53.5072C44.2745 51.0092 36.2309 51.1091 31.7345 53.4272Z"
        fill="#454445"
      />
      <path
        d="M37.6598 33.5731C37.6598 33.5731 44.5843 37.8497 48.3313 37.5699C48.3313 37.5699 60.8912 37.1502 64.3185 34.4424V32.444L48.651 33.3133C48.651 33.3133 38.3293 25.0599 35.7713 26.6187C33.2134 28.1774 37.6598 33.5731 37.6598 33.5731Z"
        fill="#C7C7C7"
      />
      <path
        d="M33.3234 32.0344C33.3234 32.0344 38.5392 38.809 42.7958 38.1096C47.0524 37.4101 49.6603 38.9189 51.0892 37.58L51.6488 33.1235C51.6488 33.1235 48.4614 32.9737 45.9634 30.9753C43.5953 28.8669 42.3062 28.0576 39.6983 28.2874C37.0904 28.5172 32.1444 29.4565 33.3234 32.0344Z"
        fill="#666666"
      />
      <path
        d="M21.0832 25.2498L21.6727 19.5743C21.6727 19.5743 17.606 13.5192 20.3138 10.9412C23.0217 8.36331 31.2051 6.55476 30.4357 19.4944C30.4357 19.4944 28.5073 22.0024 25.9193 20.4936C24.9801 19.954 27.638 26.4888 27.638 26.4888C27.638 26.4888 22.6719 29.1367 21.0832 25.2498Z"
        fill="#C7C7C7"
      />
      <mask
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="19"
        y="9"
        width="12"
        height="19"
        id="mask0_2589_355"
      >
        <path
          d="M21.0832 25.2498L21.6727 19.5743C21.6727 19.5743 17.606 13.5192 20.3138 10.9412C23.0217 8.36331 31.2051 6.55476 30.4357 19.4944C30.4357 19.4944 28.5073 22.0024 25.9193 20.4936C24.9801 19.954 27.638 26.4888 27.638 26.4888C27.638 26.4888 22.6719 29.1367 21.0832 25.2498Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2589_355)">
        <g style="mix-blend-mode: multiply" opacity="0.39">
          <path
            d="M21.9226 13.1895C21.9226 13.1895 27.8279 14.0188 27.9178 9.5524C28.0077 5.08597 21.9226 10.8214 21.9226 10.8214V13.1895Z"
            fill="#9B9B9B"
          />
        </g>
      </g>
      <path
        d="M23.751 17.3261C23.751 17.3261 22.8317 19.2745 21.7526 19.4944C21.7526 19.4944 20.6335 17.7857 19.2646 17.6358C17.8957 17.486 15.6775 14.1087 17.5959 13.0995C19.5144 12.0903 17.5959 8.10346 20.3737 7.73376C23.1515 7.36406 23.6711 9.47237 25.5995 6.33488C27.528 3.19739 29.5963 4.59627 30.2758 6.11505C31.205 8.11346 28.0276 9.35246 30.3757 9.96198C32.7238 10.5715 29.7762 11.9604 29.7762 11.9604C29.1031 11.1731 28.2635 10.5451 27.3182 10.1218C27.3182 10.1218 28.1974 12.1202 25.5696 12.5299C22.9417 12.9396 22.6719 13.5291 22.6719 13.5291C22.9482 13.6309 23.1938 13.802 23.385 14.026C23.5763 14.2499 23.7067 14.5193 23.7639 14.8082C23.8211 15.0971 23.8031 15.3959 23.7116 15.6758C23.6201 15.9557 23.4582 16.2075 23.2414 16.4068"
        fill="#666"
      />
      <path
        d="M18.875 27.8278C18.875 27.8278 16.7467 28.3174 18.6751 34.3625C19.5444 37.1603 24.5904 46.0532 24.5904 46.0532C24.5904 46.0532 27.1683 57.7338 29.0668 57.7338C32.9517 57.7639 36.7798 56.8008 40.1879 54.9361C44.1847 52.6579 44.9241 53.5272 44.9241 53.5272C44.9241 53.5272 46.1132 47.2223 38.2994 35.072C35.8214 31.2151 31.2451 27.2482 27.8778 27.0784C24.5105 26.9085 22.0524 23.6911 18.875 27.8278Z"
        fill="#8D8D8D"
      />
      <mask
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="17"
        y="25"
        width="28"
        height="33"
        id="mask1_2589_355"
      >
        <path
          d="M18.875 27.8278C18.875 27.8278 16.7467 28.3174 18.6751 34.3625C19.5444 37.1603 24.5904 46.0532 24.5904 46.0532C24.5904 46.0532 27.1683 57.7338 29.0668 57.7338C32.9517 57.7639 36.7798 56.8008 40.1879 54.9361C44.1847 52.6579 44.9241 53.5272 44.9241 53.5272C44.9241 53.5272 46.1132 47.2223 38.2994 35.072C35.8214 31.2151 31.2451 27.2482 27.8778 27.0784C24.5105 26.9085 22.0524 23.6911 18.875 27.8278Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2589_355)">
        <g style="mix-blend-mode: multiply" opacity="0.4">
          <path
            d="M16.7466 31.315L21.7426 34.3126C23.5711 35.4117 28.0975 38.3094 33.1035 36.4209C39.2686 34.0828 41.6367 35.022 41.6367 35.022L34.942 29.3166L21.0132 29.6563C21.0132 29.6563 15.0879 23.5812 16.7466 34.3226"
            fill="#8D8D8D"
          />
        </g>
      </g>
      <path
        d="M21.7526 25.7194C21.7526 25.7194 18.755 24.7202 19.0048 30.2758C19.2546 35.8313 37.7698 30.1359 38.4892 30.2758C39.2086 30.4156 51.3389 29.8461 54.946 27.5679C54.946 27.5679 58.3633 27.6479 59.3725 26.6586C60.3817 25.6694 60.7214 23.1515 60.1419 23.3013C58.1092 23.435 56.1774 24.2359 54.6463 25.5795C54.6463 25.5795 41.0771 25.5795 38.9188 26.4288C36.7606 27.2782 30.1259 25.1499 21.7526 25.7194Z"
        fill="#C7C7C7"
      />
      <path
        d="M56.5347 24.3205C56.5347 24.3205 58.5331 22.3221 58.1234 20.2338C58.1214 20.1994 58.1299 20.1652 58.1478 20.1358C58.1657 20.1063 58.1921 20.083 58.2235 20.0689C58.2549 20.0548 58.2899 20.0506 58.3238 20.0569C58.3577 20.0631 58.3888 20.0795 58.4132 20.1039C59.0926 20.8733 60.0918 22.492 58.1934 23.8509C55.5854 25.7194 56.5347 24.3205 56.5347 24.3205Z"
        fill="#FDB7B4"
      />
      <path
        d="M41.7266 25.9892C40.8261 25.848 39.9092 25.848 39.0088 25.9892C37.8197 26.219 30.4856 25.9892 29.2965 25.2798C28.1075 24.5703 21.5028 23.5012 19.7242 25.6795C17.9456 27.8577 15.8373 31.0452 19.7242 33.4233C23.6111 35.8014 28.5871 35.8014 31.4348 33.4233C34.2825 31.0452 39.2486 31.7846 40.6674 31.5548C41.5982 31.3429 42.4296 30.8216 43.0255 30.0759L41.7266 25.9892Z"
        fill="#8D8D8D"
      />
      <path d="M45.0239 60.7814L64.0787 0L99.9999 12.2602L76.4987 61.8505L45.0239 60.7814Z" fill="var(--primary)" />
      <path
        d="M62.8497 48.3813L42.9956 117.566H45.024L67.8457 49.5904L62.8497 48.3813Z"
        fill="var(--leg-one, var(--primary))"
      />
      <path
        d="M62.8496 48.3813L75.0099 117.566H77.1682L67.8456 49.5904L62.8496 48.3813Z"
        fill="var(--leg-two, var(--primary))"
      />
      <path d="M25.3697 13.4093L25.6894 13.7191C25.6894 13.7191 11.4009 24.98 25.3697 13.4093Z" fill="var(--primary)" />
      <path
        d="M25.3697 13.4092C25.3697 13.4092 24.95 12.5599 25.9093 12.6698C26.2007 12.6895 26.4921 12.6314 26.7537 12.5015C27.0153 12.3716 27.2377 12.1745 27.3981 11.9304C27.3981 11.9304 27.2882 13.719 25.6895 13.719L25.3697 13.4092Z"
        fill="var(--primary)"
      />
      <path
        d="M24.1307 15.8074C24.3299 16.0637 24.4215 16.3875 24.386 16.7102C24.3506 17.0328 24.1909 17.329 23.9408 17.536C23.633 17.652 23.2921 17.6439 22.9902 17.5134C22.6882 17.3829 22.4487 17.1402 22.3221 16.8366C22.1216 16.58 22.0301 16.2548 22.0675 15.9313C22.1049 15.6078 22.2682 15.312 22.522 15.1079C22.8288 14.9975 23.1661 15.0084 23.4651 15.1384C23.7642 15.2684 24.0022 15.5077 24.1307 15.8074Z"
        fill="#C7C7C7"
      />
    </g>
    <defs>
      <clipPath id="clip0_2589_355">
        <rect width="100" height="122.592" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
